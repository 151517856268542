import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'

import { cover, square } from 'styles/helpers'
import { white, green } from 'styles/colors'
import { borderRadius } from 'styles/variables'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import Icon from 'components/Icon'

import Checkmark from 'assets/icons/checkmark.svg'

const Container = styled.div`
  ${cover('absolute')};
  background-color: ${rgba(white, 0.9)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${rgba(green, 0.2)};
  color: ${green};
  border-radius: ${borderRadius};
  margin-bottom: ${spacing.medium};
  ${square('40px')};
`

const Title = styled(Heading)`
  margin-bottom: ${spacing.small};
`

const Success = () => (
  <Container>
    <IconWrap>
      <Icon icon={<Checkmark />} />
    </IconWrap>
    <Title size={3}>Thank you!</Title>
    <p>We'll get back to you in a timely manner.</p>
  </Container>
)

export default Success
