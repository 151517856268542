import React from 'react'
import styled from 'styled-components'

import { largerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import { Container, Wrap, Field } from './FormComponents'
import Label from 'components/Label'
import Icon from 'components/Icon'

import Chevron from 'assets/icons/chevron-down.svg'

const IconWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  pointer-events: none;
  padding-right: ${spacing.small};
  padding-left: ${spacing.tiny};

  ${largerThan.medium`
    padding-right: ${spacing.scale(spacing.small, 1.5)};
  `};
`

const Select = ({ label, id, options, ...rest }) => (
  <Container>
    {label && <Label htmlFor={id}>{label}</Label>}
    <Wrap>
      <Field as="select" id={id} {...rest}>
        {options.map((opt, i) => (
          <option
            key={i}
            value={opt.value}
            selected={opt.selected}
            disabled={opt.disabled}
          >
            {opt.label}
          </option>
        ))}
      </Field>
      <IconWrap>
        <Icon icon={<Chevron />} />
      </IconWrap>
    </Wrap>
  </Container>
)

export default Select
