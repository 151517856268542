import React from 'react'

import { Grid, GridRow, GridColumn } from 'components/Grid'
import ContactForm from './ContactForm'
import Sidebar from './Sidebar'
import Section from 'components/Section'
import Wrapper from 'components/Wrapper'

const ContactPage = () => (
  <Section flushTop>
    <Wrapper>
      <Grid>
        <GridRow>
          <GridColumn xs={12} md={8}>
            <ContactForm />
          </GridColumn>
          <GridColumn xs={12} md={4}>
            <Sidebar />
          </GridColumn>
        </GridRow>
      </Grid>
    </Wrapper>
  </Section>
)

export default ContactPage
