import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'

import { largerThan, smallerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Input from 'components/form/Input'
import Select from 'components/form/Select'
import Button from 'components/button/Button'
import { Grid, GridRow, GridColumn } from 'components/Grid'
import Success from './Success'

const Container = styled.form`
  position: relative;

  ${smallerThan.medium`
    max-width: 640px;
  `};

  ${largerThan.medium`
    padding-right: ${spacing.large};
  `};

  ${largerThan.large`
    padding-right: ${spacing.xLarge};
  `};

  ${largerThan.xLarge`
    padding-right: ${spacing.xxLarge};
  `};
`

const ContactForm = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: 'post',
      url: 'https://getform.io/f/74ee290c-7e54-403a-b4f5-2540cda33b07',
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, 'Thanks!', form)
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return (
    <Container onSubmit={handleOnSubmit}>
      <input
        type="hidden"
        name="_gotcha"
        style={{ display: 'none !important' }}
      />

      <Grid>
        <GridRow>
          <GridColumn xs={12} sm={6}>
            <Input
              name="name"
              label="Name"
              type="text"
              id="name"
              required
              // value={formValues.name}
              // onChange={handleChange}
            />
          </GridColumn>

          <GridColumn xs={12} sm={6}>
            <Input
              name="email"
              label="Email"
              type="email"
              id="email"
              required
              // value={formValues.email}
              // onChange={handleChange}
            />
          </GridColumn>

          <GridColumn xs={12} sm={6}>
            <Select
              name="budget"
              label="Budget"
              id="budget"
              options={[
                {
                  label: 'Select your budget',
                  value: '',
                  selected: true,
                  disabled: true,
                },
                {
                  label: '$50,000 - $75,000',
                  value: '$50,000 - $75,000',
                },
                {
                  label: '$75,000 - $150,000',
                  value: '$75,000 - $150,000',
                },
                {
                  label: '$150,000+',
                  value: '$150,000+',
                },
                {
                  label: 'Ongoing/retainer',
                  value: 'ongoing/retainer',
                },
                {
                  label: "I'm not sure",
                  value: "I'm not sure",
                },
              ]}
              required
              // value={formValues.budget}
              // onChange={handleChange}
            />
          </GridColumn>

          <GridColumn xs={12} sm={6}>
            <Select
              name="timeline"
              label="Timeline"
              id="timeline"
              options={[
                {
                  label: 'Select your timeline',
                  value: '',
                  selected: true,
                  disabled: true,
                },
                {
                  label: 'As soon as possible',
                  value: 'As soon as possible',
                },
                {
                  label: '2-3 months',
                  value: '2-3 months',
                },
                {
                  label: '3-6 months',
                  value: '3-6 months',
                },
                {
                  label: '6 months +',
                  value: '6 months +',
                },
              ]}
              required
              // value={formValues.timeline}
              // onChange={handleChange}
            />
          </GridColumn>

          <GridColumn xs={12}>
            <Input
              name="details"
              label="Project details"
              id="details"
              textarea
              required
              // value={formValues.details}
              // onChange={handleChange}
            />
          </GridColumn>

          <GridColumn xs={12}>
            <div>
              <Button type="submit">Send message</Button>
            </div>
          </GridColumn>
        </GridRow>
      </Grid>

      {serverState.status?.ok && <Success />}
    </Container>
  )
}

export default ContactForm
