import styled from 'styled-components'

import { fontWeights } from 'styles/typography'
import * as spacing from 'styles/spacing'

export default styled.label`
  display: block;
  margin-bottom: ${spacing.small};
  font-weight: ${fontWeights.medium};
  color: ${({ theme }) => theme.heading};
  font-size: 0.75em;
  text-align: left;
`
