import React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { StaticImage } from 'gatsby-plugin-image'

import { largerThan, smallerThan } from 'styles/media'
import { cover } from 'styles/helpers'
import { fontFamilies, fontWeights, lineHeights } from 'styles/typography'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import Wrapper from 'components/Wrapper'

const Container = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: -1px;

  ${smallerThan.medium`
    margin-top: ${spacing.xLarge};
  `};

  &:after {
    content: '';
    display: block;
    padding-bottom: 70%;

    ${largerThan.small`
      padding-bottom: 65%;
    `};

    ${largerThan.medium`
      padding-bottom: 60%;
    `};

    ${largerThan.large`
      padding-bottom: 55%;
    `};

    ${largerThan.xLarge`
      padding-bottom: 50%;
    `};
  }
`

const Background = styled.div`
  ${cover('absolute')};

  > * {
    ${cover('absolute')};
  }
`

const Inner = styled.a`
  display: block;
  position: relative;
  z-index: 10;

  ${largerThan.medium`
    top: 3rem;
  `};

  ${largerThan.large`
    top: ${spacing.large};
  `};
`

const Title = styled(Heading)`
  margin-bottom: ${spacing.small};

  ${largerThan.medium`
    margin-bottom: ${spacing.medium};
  `};
`

const Subheading = styled(Heading)`
  color: ${({ theme }) => theme.body};
  font-family: ${fontFamilies.body};
  font-weight: ${fontWeights.regular};
  line-height: ${lineHeights.base};
  margin-bottom: 0;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-image: linear-gradient(
    180deg,
    ${({ theme }) => theme.background} 0%,
    ${({ theme }) => rgba(theme.background, 0)} 100%
  );
`

const Location = () => (
  <Container>
    <Wrapper>
      <Inner
        href="https://www.google.com/maps/place/Volume7/@45.502057,-73.5715337,17z/data=!3m2!4b1!5s0x4cc91a44839364fb:0x1e3146979c917953!4m5!3m4!1s0x4cc91b3a3b3432d3:0x9088e5a8031e6ead!8m2!3d45.502057!4d-73.569345"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Title size={2}>
          We're based in beautiful
          <br />
          Montreal, Canada
        </Title>
        <Subheading size={4}>
          3 Place Ville Marie #400
          <br />
          Montreal, QC H3B 2E3 Canada
        </Subheading>
      </Inner>
    </Wrapper>

    <Background>
      <StaticImage
        src="../../assets/images/contact/montreal.jpg"
        layout="fullWidth"
        objectPosition="top center"
        placeholder="blurred"
        alt="Alt text"
      />
    </Background>
    <Overlay />
  </Container>
)

export default Location
