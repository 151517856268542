import React from 'react'

import {
  Container,
  Wrap,
  Field,
  Textarea,
  ErrorMessage,
} from './FormComponents'
import Label from 'components/Label'

const Input = ({ label, input, textarea, id, meta = {}, ...rest }) => (
  <Container>
    {label && <Label htmlFor={id}>{label}</Label>}
    <Wrap>
      {textarea ? (
        <Textarea
          id={id}
          as="textarea"
          error={meta.error && meta.touched}
          {...input}
          {...rest}
        />
      ) : (
        <Field
          as="input"
          id={id}
          error={meta.error && meta.touched}
          {...input}
          {...rest}
        />
      )}
    </Wrap>
    {meta && meta.touched && meta.error && (
      <ErrorMessage>{meta.error}</ErrorMessage>
    )}
  </Container>
)

export default Input
