import styled from 'styled-components'
import { rgba } from 'polished'

import { primaryColor, red } from 'styles/colors'
import { animationTimes, animationCurve, borderRadius } from 'styles/variables'
import { fontSizes } from 'styles/typography'
import { largerThan } from 'styles/media'

import * as spacing from 'styles/spacing'

export const Container = styled.div`
  display: block;
  width: 100%;
`

export const Wrap = styled.div`
  position: relative;
`

export const Field = styled.div`
  border-radius: ${borderRadius};
  background-color: ${({ theme }) => theme.input};
  width: 100%;
  resize: none;
  color: ${({ theme }) => theme.heading};
  vertical-align: top;
  outline: 0;
  padding: ${spacing.scale(spacing.small, 1.4)}
    ${spacing.scale(spacing.small, 1.5)};
  transition: background-color ${animationTimes.base} ${animationCurve},
    box-shadow ${animationTimes.base} ${animationCurve};

  ${largerThan.medium`
    padding: ${spacing.scale(spacing.small, 1.8)} ${spacing.medium};
  `};

  &::placeholder {
    color: ${({ theme }) => rgba(theme.body, 0.4)};
  }

  ${(props) =>
    props.error &&
    `
    box-shadow: 0 0 0 1px ${red};
  `}

  &:focus {
    background-color: ${({ theme }) => theme.background};
    box-shadow: 0 0 0 2px ${primaryColor};
  }

  &[disabled] {
    color: ${({ theme }) => theme.heading};
    pointer-events: none;
    background-color: ${({ theme }) => theme.background};
    user-select: none;
    opacity: 0.5; /* Overwrite iOS styles */
  }
`

export const Textarea = styled(Field)`
  padding: ${spacing.scale(spacing.small, 1.5)} ${spacing.medium};
  min-height: 120px;
  resize: vertical;

  ${largerThan.small`
    min-height: 140px;
  `};

  ${largerThan.medium`
    min-height: 160px;
    padding: ${spacing.medium};
  `};

  ${largerThan.large`
    min-height: 200px;
  `};
`

export const ErrorMessage = styled.span`
  color: ${red};
  display: block;
  margin-top: ${spacing.small};
  font-size: ${fontSizes.tiny};
`
