import React from 'react'

import MetaTags from 'components/MetaTags'
import PageHeader from 'components/PageHeader'
import ContactPage from 'components/contact/ContactPage'
import Location from 'components/contact/Location'

const Contact = () => (
  <>
    <MetaTags title="Contact | Custom software development in Montreal | Volume7" />
    <PageHeader
      heading={
        <>
          Let's work <span>together</span>
        </>
      }
      description="Please provide a short description of your project and we'll do our best to get back to you in a timely manner."
    />
    <ContactPage />
    <Location />
  </>
)

export default Contact
