import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { largerThan, smallerThan } from 'styles/media'
import * as spacing from 'styles/spacing'

import Heading from 'components/Heading'
import RichText from 'components/RichText'
import SocialLinks from 'components/SocialLinks'

const Container = styled.aside`
  padding-top: ${spacing.large};
  margin-top: ${spacing.small};

  ${largerThan.small`
    padding-top: ${spacing.xLarge};
  `};

  ${smallerThan.medium`
    border-top: 1px solid ${({ theme }) => theme.border};
    max-width: 640px; /* Match contact form */
  `};

  ${largerThan.medium`
    padding-top: 0;
    margin-top: 0;
  `};

  ${largerThan.large`
    padding-left: ${spacing.large};
  `};

  ${largerThan.xLarge`
    padding-left: ${spacing.xLarge};
  `};
`

const Section = styled.section`
  position: static;

  & + & {
    margin-top: ${spacing.large};

    ${largerThan.medium`
      margin-top: ${spacing.xLarge};
    `};

    ${largerThan.xLarge`
      margin-top: ${spacing.xxLarge};
    `};
  }
`

const Sidebar = () => (
  <Container>
    <Section>
      <Heading size={5}>Want to join our team?</Heading>
      <RichText>
        <p>
          We're always on the lookout for exciting candidates.{' '}
          <Link to="/careers/#openings">View openings</Link>
        </p>
      </RichText>
    </Section>
    <Section>
      <Heading size={5}>Something else?</Heading>
      <RichText>
        <p>
          We're happy to answer any question you may have.{' '}
          <a href="mailto:hello@volume7.io">General inquiries</a>
        </p>
      </RichText>
    </Section>
    <Section>
      <SocialLinks />
    </Section>
  </Container>
)

export default Sidebar
